import { computed, reactive, readonly, ref, watch } from "vue";
import { arrayRemove, uniquePush } from "@/hive-vue3/utils/arrayUtils";
import { isObject, objectsDeepEqual } from "@/hive-vue3/utils/objectUtils";
import { getDateFromTsOrDate } from "@/hive-vue3/firebase";

export default function (initialValues, isDebugging) {
  const values = reactive({});
  const valids = reactive({});
  const types = {};
  const models = {};
  const keys = [];
  // let updateFn;
  // function onUpdate(fn) {
  //   updateFn = fn;
  // }
  // function update() {
  //   if (updateFn) updateFn(values);
  // }
  const initialValuesRef = ref(initialValues || {});
  const formCancelHooks = [];
  function getValueByKey(obj, key) {
    const dot = key.indexOf(".");
    if (dot > 0) {
      const parent = key.substring(0, dot);
      const child = key.substring(dot + 1);
      if (obj[parent]) {
        // console.log(key, parent, child, obj[parent]);
        if (obj[parent][child] !== undefined) {
          const isDate = getDateFromTsOrDate(obj[parent][child]);
          if (isDate instanceof Date) {
            return isDate;
          }
          // 使用复制值以避免修改初始值
          return JSON.parse(JSON.stringify(obj[parent][child]));
        }
        return undefined;
      }
      return undefined;
    } else {
      if (obj[key] !== undefined) {
        const isDate = getDateFromTsOrDate(obj[key]);
        if (isDate instanceof Date) {
          return isDate;
        }
        // 使用复制值以避免修改初始值
        return JSON.parse(JSON.stringify(obj[key]));
      }
      return undefined;
    }
  }
  const register = (key, { model, valid, type, onFormCancel }) => {
    if (onFormCancel) {
      formCancelHooks.push(onFormCancel);
    }
    // console.log("register", key);
    //initialValues only work when model is undefined. e.g. no v-model for the control initialised.
    // console.log(model.value, initialValuesRef, key);
    if (model.value === undefined)
      model.value = getValueByKey(initialValuesRef.value, key);
    if (type) types[key] = type;
    models[key] = model;
    keys.push(key);
    watch(
      model,
      (v) => {
        // console.log("modelchange", v);
        const dot = key.indexOf(".");
        if (dot > 0) {
          const parent = key.substring(0, dot);
          const child = key.substring(dot + 1);
          if (values[parent]) {
            if (v === undefined) {
              delete values[parent][child];
            } else {
              values[parent][child] = v;
            }
          } else if (v !== undefined) {
            values[parent] = {};
            values[parent][child] = v;
          }
        } else {
          values[key] = v;
        }
        changed.value = checkChanged();
        // update();
      },
      { immediate: true, deep: true }
    );
    watch(valid, () => (valids[key] = valid.value), { immediate: true });
  };
  const valid = computed(() => {
    // console.log(valids);
    for (let v in valids) {
      if (!valids[v]) return false;
    }
    // console.log("valid");
    return true;
  });
  // const changed = computed(() => {
  //   // console.log("check changed");
  //   for (const key in keys) {
  //     const val = values[key];
  //     const isNull = val === null || val === undefined;
  //     let initVal;
  //     if (initialValuesRef.value) {
  //       initVal = getValueByKey(initialValuesRef.value, key);
  //     }
  //     console.log(key, initVal, val);
  //     if (isNull) {
  //       if (initVal) return true;
  //     } else {
  //       if (!initVal) return true;
  //       if (val !== initVal) return true;
  //     }
  //     // if (val !== null && val !== undefined) {
  //     //   if (!initialValuesRef.value) return true;
  //     //   if (val !== initialValuesRef.value[key]) return true;
  //     // }
  //   }
  //   // console.log("not changed");
  //   return false;
  // });
  const changed = ref(false);
  function checkChanged() {
    // console.log("keys", keys);
    for (const key of keys) {
      // console.log(key);
      const val = getValueByKey(values, key);
      const isNull = val === null || val === undefined;
      let initVal;
      if (initialValuesRef.value) {
        initVal = getValueByKey(initialValuesRef.value, key);
      }
      if (isNull) {
        if (initVal) {
          // console.log("changed", key, initVal, val);
          return true;
        }
      } else {
        if (!initVal === undefined || val !== initVal) {
          // console.log("check changed", key, initVal, val);
          // 判断数组成员是否更改
          if (Array.isArray(val) && Array.isArray(initVal)) {
            if (JSON.stringify(val) !== JSON.stringify(initVal)) return true;
          } else if (val instanceof Date && initVal instanceof Date) {
            if (val.getTime() !== initVal.getTime()) {
              return true;
            }
          }
          // 判断object内部更改;
          else if (isObject(val) && isObject(initVal)) {
            if (!objectsDeepEqual(val, initVal))
              // console.log("deep equal", val, initVal);
              return true;
          } else {
            // console.log("changed", key, initVal, val);
            return true;
          }
        }
      }
      // if (val !== null && val !== undefined) {
      //   if (!initialValuesRef.value) return true;
      //   if (val !== initialValuesRef.value[key]) return true;
      // }
    }
    // console.log("not changed");
    return false;
  }
  function cancel() {
    for (let i = 0; i < formCancelHooks.length; i++) {
      formCancelHooks[i]();
    }
  }
  const isHolding = ref(false);
  const holdingKeys = [];
  function hold(key) {
    uniquePush(holdingKeys, key);
    isHolding.value = true;
  }
  function unhold(key) {
    arrayRemove(holdingKeys, key);
    if (holdingKeys.length === 0) isHolding.value = false;
  }

  /**
   *
   * @param key null will reset all values
   */
  function reset(key = null) {
    if (key) {
      const val = getValueByKey(initialValuesRef.value, key);
      models[key].value = val;
    } else {
      for (let k of keys) {
        reset(k);
      }
    }
  }
  return {
    register,
    cancel,
    hold,
    unhold,
    /**
     * used when control is not ready
     */
    isHolding: readonly(isHolding),
    valid,
    /**
     * Non reactive
     * {Object}
     */
    values,
    /**
     * none reactive
     */
    types,
    /**
     * if data is changed from initialValues
     */
    changed,
    /**
     * set/update initialValues
     * used when initial values are loaded from server with delays.
     * @param v{Object}
     */
    initialValues(v) {
      // console.log(v);
      if (!v) return;
      initialValuesRef.value = v;
      for (const key in v) {
        // console.log(models[key]);
        if (models[key]) models[key].value = v[key];
      }
    },
    reset,
    isDebugging,
  };
}
