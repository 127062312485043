const letters = "0123456789ABCDEF";
export function randomColor() {
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
export function blackOpacity(opacity) {
  return `rgba(0, 0, 0, ${opacity})`;
}
export function whiteOpacity(opacity) {
  return `rgba(255, 255, 255, ${opacity})`;
}
