import { onMounted, watch } from "vue";
import { isFunction } from "lodash/lang";

export function lazyRef(ref, delay = 200) {
  let timeout;
  let _delay = delay;
  const lazySet = (v, delay) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      // console.log(v, v.callee);
      if (isFunction(v)) ref.value = v();
      else ref.value = v;
    }, delay || _delay);
  };
  const setNow = (v) => {
    if (timeout) clearTimeout(timeout);
    ref.value = v;
  };
  return {
    lazy: lazySet,
    now: setNow,
  };
}
export function lazyWatch(source, act, delay = 200) {
  let timeout;
  const func = (v) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => act(v), delay);
  };
  watch(source, func, { immediate: true });
}
export function lazyWatchOnMounted(source, act, { delay = 200 } = {}) {
  onMounted(() => {
    act();
    lazyWatch(source, act, delay);
  });
}
