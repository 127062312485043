<template>
  <div
    style="
      transition: height 0.4s ease-in;
      overflow-y: hidden;
      max-width: 100%;
      word-break: break-all;
      word-wrap: break-word;
      hyphens: auto;
    "
    :style="{
      height: height + 'px',
    }"
  >
    <div
      ref="messageDiv"
      class="hi-form-error text-left break-all max-w-full"
      v-html="modelValue"
    ></div>
  </div>
</template>

<script>
import { templateRef } from "@vueuse/core";
import { watchModelOnMounted } from "@/hive-vue3/utils/reactiveHelpers/watchers";
import { lazyRef } from "@/hive-vue3/utils/reactiveHelpers/lazy";
import { ref } from "vue";

export default {
  name: "HiFormError",
  props: {
    modelValue: [String, Object],
  },
  setup(props) {
    const messageDiv = templateRef("messageDiv");
    const height = ref(0);
    const lazyHeight = lazyRef(height, 400);
    watchModelOnMounted(props, async (v) => {
      if (!v || !v.length) {
        lazyHeight.now(0);
      } else {
        lazyHeight.lazy(() => {
          // console.log(messageDiv.value.clientHeight);
          if (!messageDiv.value) return;
          return messageDiv.value.clientHeight;
        });
      }
    });
    return { height };
  },
};
</script>

<style scoped>
.hi-form-error {
  /**
  todo: tailwind config error style
   */
  @apply text-error text-xxs p-1;
}
</style>
