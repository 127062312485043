<template>
  <div class="inset-0 absolute z-modal" v-if="modelValue">
    <transition-fade>
      <div
        v-if="modelValue"
        :style="{ background: bg }"
        class="absolute z-10 inset-0 flex-center text-primary"
      >
        <hi-spinner class="w-5vh opacity-50" />
      </div>
    </transition-fade>
  </div>
</template>

<script>
import { computed } from "vue";
import HiSpinner from "../HiSpinner";
import TransitionFade from "../../transitions/TransitionFade";
import { blackOpacity, whiteOpacity } from "@/hive-vue3/utils/colors";
export default {
  name: "HiLoadingMask",
  props: {
    modelValue: Boolean,
    opacity: {
      type: Number,
      default: 0.6,
    },
    black: Boolean,
  },
  components: { HiSpinner, TransitionFade },
  setup(props) {
    const bg = computed(() => {
      if (props.black) {
        return blackOpacity(props.opacity);
      } else {
        return whiteOpacity(props.opacity);
      }
    });
    return {
      bg,
    };
  },
};
</script>

<style scoped></style>
