import { watch, inject, ref, onMounted } from "vue";
import { watchModelOnMounted } from "@/hive-vue3/utils/reactiveHelpers/watchers";
import injectionKeys from "@/hive-vue3/components/_commonLogic/injectionKeys";
import { v1 } from "uuid";
import { capitalize, decamelize } from "@/hive-vue3/utils/stringUtils";

// import { useVModel } from "@vueuse/core";

/**
 *
 * @param props
 * @param context
 * @param type value type, see FORM_DATA_TYPES
 * @param validator {Function} a function return true if valid or a String when invalid with error message.
 * @param onModelValueUpdate {Function} callback function when modelValue set from parent component. 这个主要用来根据当前值更新界面用。Useful to display initial value(when ui not reactive directly to props) or validate model value & update ui when update by outside.
 * @param onFormCancel {Function}
 * @returns {{valid: Ref<UnwrapRef<boolean>>, touch: <Function>, model: ToRef<undefined>, error: ToRef<undefined>}}
 */
export default function (
  props,
  context,
  {
    type = null,
    validator = null,
    onModelValueUpdate = null,
    //考虑到 cancel 以后有些组件需要清理些垃圾，譬如 storage file 需要删掉数据库临时上传的文件。
    onFormCancel,
  } = {}
) {
  const uid = v1();
  // console.log(context);
  const model = ref(props.modelValue);

  const key = props.formKey;
  const dataControl = inject(injectionKeys.HI_FORM_DATA, undefined);
  function mandatory() {
    return props.mandatory || props.required || false;
  }
  const valid = ref(!mandatory());
  const touched = ref(false);
  const error = ref(undefined);
  watch(model, async (v) => {
    context.emit("update:modelValue", v);
    // console.log("model", v);
    valid.value = await validate();
  });

  if (key && !props.noForm) {
    if (dataControl === undefined) {
      console.warn(`No related form dataControl for key: '${key}' found!`);
    } else {
      // console.log("register", key);
      dataControl.register(key, { type, model, valid, onFormCancel });
      onMounted(() => {
        if (model.value !== undefined && onModelValueUpdate) {
          //need to update control if there's initial value
          onModelValueUpdate(model.value);
        }
      });
    }
  } else if (dataControl && !props.noForm) {
    console.warn(`formKey not provide but the control is within a HiForm!`);
  }

  watchModelOnMounted(props, (v) => {
    // console.log("formControl update", v);
    // console.log("formControl update", v.value);
    if (v === model.value) return;
    model.value = v;
    // console.log(v);
    touch();
    if (onModelValueUpdate) {
      onModelValueUpdate(v);
    }
  });
  if (model.value) {
    touch();
  }
  function setError(msg) {
    if (!touched.value) return;
    error.value = msg;
  }
  function clearError() {
    error.value = undefined;
  }
  function hold(id = uid) {
    // console.log("hold " + key, id);
    dataControl && dataControl.hold && dataControl.hold(id);
  }
  function unhold(id = uid) {
    // console.log("unhold " + key, id);
    dataControl && dataControl.unhold && dataControl.unhold(id);
  }
  async function validate() {
    //'-v' 用来区分 exposed hold() function
    hold(uid + "-v");
    const value = model.value;
    let err = false;
    if ((validator || props.validator) && value && value !== "") {
      let result = true;
      if (validator) result = await validator(value);
      if (result === true && props.validator) {
        // console.log(props.validator);
        result = await props.validator(value);
      }
      if (result !== true) {
        if (typeof result === "string") {
          setError(result);
        } else {
          setError("Unknown Error.");
        }
        err = true;
      }
    } else if (mandatory()) {
      if (value === null || value === undefined) {
        err = true;
      } else if (typeof value === "string" && value.trim().length === 0) {
        err = true;
      }
      if (err) {
        setError(`${capitalize(decamelize(key))} is mandatory.`);
      }
    }
    unhold(uid + "-v");
    if (err) {
      return false;
    } else {
      clearError();
      return true;
    }
  }

  /**
   * called when user interacted the control
   * after touch() the error message starts to display
   */
  async function touch() {
    touched.value = true;
    valid.value = await validate();
  }

  return {
    //ref<Boolean> to indicate value is valid
    valid,
    //ref<any> the value of the control.
    model,
    //function called when user interacted the control.
    touch,
    //String the error message
    error,
    hold,
    unhold,
    isDebugging: dataControl && dataControl.isDebugging,
    reset() {
      dataControl.reset(key);
    },
  };
}
