<template>
  <svg
    :width="size"
    :height="size"
    :viewBox="viewBox"
    :style="computedStyles"
    class="flex-shrink-0 shrink-0"
  >
    <path :d="path" />
  </svg>
</template>

<script>
import { computed } from "vue";

export default {
  name: "hi-icon",
  props: {
    size: {
      type: [Number, String],
      default: "1em",
    },
    color: {
      type: String,
      default: "currentColor",
    },
    path: {
      type: String,
      required: true,
    },
    viewBox: {
      type: String,
      default: "0 0 24 24",
    },
    styles: Object,
  },
  setup(props) {
    return {
      computedStyles: computed(() => {
        return Object.assign(
          {
            fill: props.color,
            display: "inline-block",
          },
          props.styles
        );
      }),
    };
  },
};
</script>

<style scoped></style>
