<template>
  <span>
    <span class="inline-flex items-start align-middle">
      <span class="flex items-center h-5">
        <input
          :disabled="disabled"
          :id="name"
          :name="name"
          type="checkbox"
          class="focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded"
          v-model="model"
        />
      </span>
      <span class="ml-3 text-sm">
        <label :for="name" class="font-medium text-gray-700"><slot /></label>
        <span class="text-gray-500 block">
          <slot name="description" />
        </span>
      </span>
    </span>
  </span>
</template>

<script>
import { v1 } from "uuid";
import formControl from "../logics/formControl";
// import syncModelToFirestore from "./dataControl/syncModelToFirestore";

export default {
  name: "HiCheck",
  props: {
    noForm: Boolean,
    formKey: String,
    modelValue: {
      type: Boolean,
      default: undefined,
    },
    disabled: Boolean,
  },
  setup(props, context) {
    const control = formControl(props, context);
    const name = props.formKey || v1();
    return {
      name,
      model: control.model,
    };
  },
};
</script>

<style scoped>
input[type="checkbox"] {
  -webkit-appearance: none; /*去除默认样式*/
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
}
</style>
