import download from "downloadjs";
import mime from "@/hive-vue3/utils/constables/mime";
export function saveText(text, fileName) {
  download(text, fileName, "text/plain");
}

export function savePng(data, fileName) {
  download(data, fileName, mime.ImagePng);
}

export function saveFile(data, fileName, mime) {
  download(data, fileName, mime);
}

/**
 * 配置跨域
 * https://cloud.google.com/storage/docs/configuring-cors#gsutil_2
 *
 * @param url
 * @param fileName
 * @param fileType
 */
export function downloadFileFromUrl(url, fileName, fileType) {
  const x = new XMLHttpRequest();
  x.open("GET", url, true);
  x.responseType = "blob";
  x.onload = function (e) {
    download(e.target.response, fileName, fileType);
  };
  x.send();
}
