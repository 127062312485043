<template>
  <span class="hi-btn" :class="classes">
    <slot />
  </span>
</template>

<script>
import { computed } from "vue";

export default {
  name: "HiButton",
  props: {
    primary: Boolean,
    text: Boolean,
    outline: Boolean,
    secondary: {
      type: Boolean,
      default: true,
    },
    disabled: Boolean,
    small: Boolean,
    /**
     * To remove all paddings. for customise paddings when needed.
     */
    tight: Boolean,
    wide: Boolean,
  },
  setup(props) {
    const classes = computed(() => {
      const classes = [];
      if (props.text) {
        classes.push("-text");
      }
      if (props.primary) {
        classes.push("-primary");
      } else if (props.secondary) {
        classes.push("-secondary");
      }
      if (props.outline) {
        classes.push("-outline");
      }

      if (props.disabled) {
        classes.push("disabled");
      }
      if (props.small) classes.push("-small");
      if (props.tight) classes.push("-tight");
      if (props.wide) classes.push("-wide");
      return classes;
    });
    return {
      classes,
    };
  },
};
</script>
