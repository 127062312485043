// import { ref } from "vue";
import { effectScope, onMounted, watch } from "vue";

/**
 *
 * @param propsObj
 * @param props {String|Array}
 * @param act {Function}
 * @param immediate {Boolean}
 */
export function watchProps(propsObj, props, act, immediate = false) {
  if (typeof props === "string") {
    watch(() => propsObj[props], act, { immediate });
  } else {
    const watches = [];
    for (let i = 0; i < props.length; i++) {
      watches.push(() => propsObj[props[i]]);
    }
    watch(watches, act, { immediate });
  }
}

/**
 *
 * @param propsObj
 * @param props {String|Array}
 * @param act {Function}
 */
export function watchPropsImmediately(propsObj, props, act) {
  watchProps(propsObj, props, act, true);
}

/**
 *
 * @param propsObj
 * @param props {String|Array}
 * @param act {Function}
 * @param immediate {Boolean}
 */
export function watchPropsOnMounted(propsObj, props, act, immediate = true) {
  onMounted(() => {
    watchProps(propsObj, props, act, immediate);
  });
}

export function watchModel(
  props,
  cb,
  modelName = "modelValue",
  { immediate } = {}
) {
  watch(
    () => props[modelName],
    (v, old) => {
      cb(v, old);
    },
    { immediate }
  );
}
export function watchModelImmediately(props, cb, modelName = "modelValue") {
  watch(
    () => props[modelName],
    (v, old) => {
      cb(v, old);
    },
    { immediate: true }
  );
}
export function watchModelOnMounted(
  props,
  cb,
  modelName = "modelValue",
  { immediate } = { immediate: false }
) {
  onMounted(() => watchModel(props, cb, modelName, { immediate }));
}
export function watchOnMounted(source, cb) {
  onMounted(() => {
    watch(source, cb, { immediate: true });
  });
}
export function watchForever(source, cb) {
  const scope = effectScope(true);
  scope.run(() => {
    watch(source, cb);
  });
  return scope;
}
export function watchImmediatelyAndForever(source, cb) {
  const scope = effectScope(true);
  scope.run(() => {
    watch(source, cb, { immediate: true });
  });
  return scope;
}
export function watchPropsForever(reactiveObj, props, cb) {
  const scope = effectScope(true);
  scope.run(() => {
    watchProps(reactiveObj, props, cb);
  });
  return scope;
}
export function watchPropsImmediatelyAndForever(reactiveObj, props, cb) {
  const scope = effectScope(true);
  scope.run(() => {
    watchPropsImmediately(reactiveObj, props, cb);
  });
  return scope;
}
