export function squeeze(obj) {
  for (let key in obj) {
    const val = obj[key];
    if (val === undefined || val === null) {
      delete obj[key];
    }
  }
}
export function deleteAllItemsInObject(obj) {
  for (let key in obj) {
    delete obj[key];
  }
}

export function objectsDeepEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    console.log("keylength unequal", keys1, keys2);
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !objectsDeepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      console.log("other unequal");
      return false;
    }
  }
  return true;
}
export function isObject(object) {
  return object != null && typeof object === "object";
}
