<template>
  <transition
    @before-enter="beforeEnter"
    @enter="enter"
    @leave="leave"
    :css="false"
    mode="out-in"
    :appear="appear"
    class="transition-container"
  >
    <slot />
  </transition>
</template>

<script>
import gsap from "gsap";
import { watchEffect } from "vue";

/**
 * Transition speed default 1s
 * Use yPercent to achieve fade up/down effect.
 */
export default {
  name: "TransitionFade",
  props: {
    slow: Boolean, //1.5s
    slower: Boolean, //2s
    fast: Boolean, //0.5s
    faster: Boolean, //0.3s
    yPercent: {
      type: Number,
      default: 0,
    },
    delay: {
      type: Number,
      default: 0,
    },
    appear: Boolean,
    /**
     * @deprecated use endOpacity instead
     */
    opacity: {
      type: Number,
      default: 1,
    },
    startOpacity: {
      type: Number,
      default: 0,
    },
    endOpacity: {
      type: Number,
    },
  },
  setup(props) {
    let duration = 1;
    let durationLeave = 0.5;
    watchEffect(() => {
      if (props.slow) duration = 1.5;
      else if (props.slower) {
        duration = 2;
        durationLeave = 1;
      } else if (props.fast) {
        duration = 0.5;
        durationLeave = 0.3;
      } else if (props.faster) {
        duration = 0.3;
        durationLeave = 0.2;
      }
    });
    return {
      beforeEnter(el) {
        // console.log("beforeenter", el);
        gsap.set(el, {
          opacity: props.startOpacity,
          yPercent: props.yPercent,
        });
      },
      enter(el, done) {
        // console.log("enter", el, props.endOpacity);
        // gsap.killTweensOf(el);
        gsap.to(el, {
          duration: duration,
          opacity: props.endOpacity || props.opacity,
          ease: "circ.out",
          onComplete: done,
          yPercent: 0,
          delay: props.delay,
        });
      },
      leave(el, done) {
        // console.log("leave", el);
        // gsap.killTweensOf(el);
        gsap.to(el, {
          duration: durationLeave,
          delay: 0.1, //add delay to improve smoothness
          opacity: props.startOpacity,
          yPercent: props.yPercent,
          ease: "circ.out",
          onComplete: done,
        });
      },
    };
  },
};
</script>
