<template>
  <div class="relative max-w-full">
    <hi-form-error
      class="bg-error-bg border-error-border rounded mb-4"
      v-model="state.errorMessage"
    />

    <hi-loading-mask v-model="state.submitting" />
    <transition-fade>
      <slot v-if="state.successful" name="successful" />
      <hi-form
        v-else
        :submit-text="submitText"
        :initial-values="initialValues"
        :disabled="state.submitting"
        @submit="submit"
        @cancel="$emit('cancel')"
        @changed="(v) => $emit('changed', v)"
        :cancel-button="cancelButton"
        :debug="debug"
        :submit-without-change="submitWithoutChange"
      >
        <template v-slot:tools-left> <slot name="tools-left" /></template>
        <template v-slot:tools-right> <slot name="tools-right" /></template>
        <slot
      /></hi-form>
    </transition-fade>
  </div>
</template>

<script>
import HiForm from "@/hive-vue3/components/form/HiForm";
import { reactive } from "vue";
import HiLoadingMask from "@/hive-vue3/components/overlays/HiLoadingMask";
import HiFormError from "@/hive-vue3/components/form/HiFormError";
import TransitionFade from "@/hive-vue3/transitions/TransitionFade";
export default {
  name: "HiFormHiveStandard",
  components: { TransitionFade, HiFormError, HiLoadingMask, HiForm },
  props: {
    /**
     * Needs to be async function
     */
    submitFn: {
      type: Function,
      required: true,
    },
    cancelButton: Boolean,
    initialValues: Object,
    debug: Boolean,
    submitWithoutChange: Boolean,
    submitText: {
      type: String,
      default: "Submit",
    },
  },
  emits: ["submit", "cancel", "successful", "error", "changed"],
  setup(props, { emit }) {
    const state = reactive({
      submitting: false,
      error: false,
      errorMessage: "",
      successful: false,
    });
    async function submit(data) {
      if (!props.submitFn) {
        console.error("HiFormHiveStandard needs a submitFn as a prop!");
        return;
      }
      state.submitting = true;
      const result = await props.submitFn(data);
      state.submitting = false;
      if (result.successful) {
        state.successful = true;
        state.error = false;
        state.errorMessage = "";
        emit("successful");
      } else {
        state.successful = false;
        state.error = true;
        state.errorMessage = result.errorMessage;
        emit("error", result.errorMessage);
      }
    }
    return {
      state,
      submit,
    };
  },
};
</script>

<style scoped></style>
