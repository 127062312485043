// export const toCamelCase = (str) =>
//   str
//     .replace(/(?:^\w|[A-Z]|\b\w)/g, (ltr, idx) =>
//       idx === 0 ? ltr.toLowerCase() : ltr.toUpperCase()
//     )
//     .replace(/\s+/g, "");

/**
 * Camelize a string, cutting the string by multiple separators like
 * hyphens, underscores and spaces.
 *
 * @return string Camelised text
 * @param text
 */
export function camelize(text) {
  return text.replace(/^([A-Z])|[\s-_]+(\w)/g, function (match, p1, p2) {
    if (p2) return p2.toUpperCase();
    return p1.toLowerCase();
  });
}
/**
 * Decamelizes a string with/without a custom separator (underscore by default).
 *
 * @param str String in camelcase
 * @param separator Separator for the new decamelized string.
 */
export function decamelize(str, separator = " ") {
  // separator = typeof separator === "undefined" ? " " : separator;

  return str
    .replace(/([a-z\d])([A-Z])/g, "$1" + separator + "$2")
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, "$1" + separator + "$2")
    .toLowerCase();
}
export function getFileExtension(fileName) {
  return fileName.split(".").pop();
}

export function capitalize(str) {
  return str.replace(/^\w/, (c) => c.toUpperCase());
}
export function numericOnly(str) {
  return str.replace(/\D/g, "");
}
export function beautifyAsTitle(str) {
  return capitalize(str.replace(/[-_]/, " "));
}
