export const uniquePush = (arr, val) => {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === val) return;
  }
  arr.push(val);
};
export const arrayRemove = (arr, ...values) => {
  for (let i = 0; i < values.length; i++) {
    const val = values[i];
    while (arr.indexOf(val) !== -1) {
      arr.splice(arr.indexOf(val), 1);
    }
  }
};
export const arrayRemoveByKey = (arr, key, ...values) => {
  for (let i = 0; i < values.length; i++) {
    const val = values[i];
    for (let j = arr.length - 1; j >= 0; j--) {
      const compare = arr[j] && arr[j][key];
      if (compare === val) {
        arr.splice(j, 1);
      }
    }
  }
};
export function forEachMaybeArray(objectOrArray, fn) {
  if (objectOrArray.forEach) {
    objectOrArray.forEach(fn);
  } else {
    fn(objectOrArray);
  }
}
export function sortAlphabetically(arr) {
  return arr.sort((a, b) => a.localeCompare(b, "en", { sensitivity: "base" }));
}
