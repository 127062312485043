<template>
  <div
    class="center-box-container"
    :style="{ display: inline ? 'inline-flex' : 'flex' }"
    :class="{ 'box-h-center': hCenter }"
  >
    <slot />
  </div>
</template>

<script>
/**
 * Center everything vertically within the container.
 * Set h-center=false to turn off horizontal centering.
 */
export default {
  name: "CenterBox",
  props: {
    inline: Boolean,
    hCenter: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped>
.center-box-container {
  flex-direction: column;
  /*align-items: center;*/
  justify-content: center;
  height: 100%;
}
.box-h-center {
  align-items: center;
}
</style>
